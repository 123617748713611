import React from "react"
import { Box, Heading, Paragraph } from "grommet"

import { themeExtension } from "../../styles/theme"

import Wrapper from "../../components/Container/Wrapper"

const DynamicPricing = () => {
  return (
    <Wrapper background={themeExtension.colors.backgrounds.light}>
      <Box
        background="white"
        pad="large"
        style={{
          boxShadow: themeExtension.shadows.graySoft,
        }}
        round="small"
      >
        <Heading level="2" margin={{ vertical: "none" }}>
          Dynamic Pricing
        </Heading>
        <Paragraph fill margin={{ top: "small", bottom: "none" }}>
          Pricing based on monthly “active devices”. Only devices used will be
          billed for every active day per month. A device is active when it is
          paired with cargo and it has left a defined perimeter (yard, port,
          etc.), so you can pair in advance or have assets stored in designated
          spaces without incurring any costs. Get in touch to learn more about
          the prices we can offer to you.
        </Paragraph>
      </Box>
    </Wrapper>
  )
}

export default DynamicPricing
